import React, { FunctionComponent, useState, useEffect } from 'react'; 
import { Row, Col, Card } from 'react-bootstrap';
import { parseToCoin, firstMonthPrice } from '../../services/AmountService';

type ProductResumeProps = {
    title: string,
    priceMonthly: number,
    priceYear: number,
    prorated: boolean,
    annualPayment: boolean
    letMonths: number
}

const ProductResumeCard:FunctionComponent<ProductResumeProps> = (props) => {

  const { title, priceMonthly, priceYear, prorated, annualPayment, letMonths } = props;

  const [ priceProduct, setPriceProduct ] = useState(0);
  const [ proratedPrice, setProratedPrice ] = useState(0);
  const  [onetime, setOnetime] = useState(false);

  useEffect(() => {
    if (priceMonthly==0) {setOnetime(true);setPriceProduct(priceYear)} else {
    const showPrice = annualPayment ? (firstMonthPrice(priceYear) + (priceYear * letMonths)) : priceMonthly;
    setPriceProduct(showPrice)
    if (prorated) {
      const p = annualPayment ? (firstMonthPrice(priceYear) + (priceYear * letMonths)) : firstMonthPrice(priceMonthly);
      setProratedPrice(p);
    }    
  }
  }, [annualPayment])

  return(
    <Card className="product-resume-card mb-3">
      <Card.Body className="p-3">
        <Card.Title>
          <Row>
            <Col md="6"><span className="title-product">{title}</span></Col>
            <Col md="6" className="text-right">
              {onetime ?
              <span><span className="product-price">{annualPayment ? parseToCoin(priceYear) : parseToCoin(priceProduct)}</span>/una vez</span>
              : <span><span className="product-price">{annualPayment ? parseToCoin(priceYear*12) : parseToCoin(priceMonthly)}</span>{annualPayment ? '/año' : '/mes'}</span>
              }
            </Col>
          </Row>
        </Card.Title>
        <div className="border-top my-2"></div>
        {onetime ?
        <Card.Text className="text-right">
          Pago único.
        </Card.Text>
        : <Card.Text className="text-right">
        Pagará esta cantidad más comisiones una vez el primer día de cada {annualPayment ? 'año' : 'mes'}.
        Ahora pagarás el precio prorateado de <span className="product-price">{parseToCoin(proratedPrice)}</span >.
      </Card.Text>
      }
      </Card.Body>
    </Card>
  );
}

export default ProductResumeCard;