import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../configs/routers/dashboard-routers-private.json';
import { Row, Col, Alert, Card, Button, Form, FormControl, Spinner, InputGroup, Container  } from 'react-bootstrap';
import ObexRequestsHandler from '../../handlers/ObexRequestsHandler';
import ButtonSubmit from '../basicComponents/buttonSubmit';
import SessionService from '../../services/SessionsService';


type FxExchangeProps = {}


const FxExchangeBenefit:FunctionComponent<FxExchangeProps> = (props) => {

  //const { } = props;

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState(Array());
  const [nombre, setName] = useState('');
  const [apellido1, setApellido1] = useState('');
  const [apellido2, setApellido2] = useState('');
  const [direccion, setDireccion] = useState('');
  const [localidad, setLocalidad] = useState('');
  const [codigopostal, setCodigoPostal] = useState('');
  const [telefono, setPhone] = useState('');
  const [email, setEmail] = useState('');
  
  
  
  const [stillLoaded , setStillLoaded] = useState(true);


  useEffect(()  => {
    setName(SessionService.ResultadoLogin["nombre"]);
    setApellido1(SessionService.ResultadoLogin["apellido1"]);
    setApellido2(SessionService.ResultadoLogin["apellido2"]);
    setEmail(SessionService.ResultadoLogin["e_mail"]);
    setPhone(SessionService.ResultadoLogin["telefono"]);
    setLocalidad(SessionService.ResultadoLogin["estado"]);

    setStillLoaded(false);
  },[]);


  return(
    <DashLayout sider={DashRouters} active={'personal'}>
      <Row>
        <Col md="9" className="mx-auto">
          <Row>
            <Col>
            <h1 className="mb-3">Datos del Empleado</h1>
            </Col>
          </Row>
          {stillLoaded ?
      <Container className="vh-100 d-flex flex-column vw-100"> 
        <Row className="h-100" >
          <Col md="2" className="mx-auto d-flex align-items-center">
            <Spinner animation="border"/>
          </Col>
        </Row>
      </Container>
      :
          <><Row>
              <Col>
                <Card className="product-detail-card mb-3">
                  <Card.Body className="p-3">
                    <Row>
                      <Col className="mx-auto">
                        <Form className="obex-form" >
                          <Form.Row className="mb-3">
                            <Form.Label column sm="12" className="obex-form-label">
                              Nombre del Empleado
                            </Form.Label>
                            <Col md="12">
                              <Form.Control required type="text" value={nombre} placeholder="Nombre" onChange={null} />
                            </Col>
                          </Form.Row>

                          <Form.Row className="mb-3">
                            <Form.Label column sm="12" className="obex-form-label">
                              Apellidos del Empleado
                            </Form.Label>
                            <Col md="6">
                              <Form.Control type="text" value={apellido1} placeholder="Apellido Paterno" onChange={null} />
                            </Col>
                            <Col md="6">
                              <Form.Control type="text" value={apellido2} placeholder="Apellido Materno" onChange={null} />
                            </Col>
                          </Form.Row>


                          <Form.Row className="mb-3">
                            <Form.Label column sm="12" className="obex-form-label">
                              Direccion del Empleado
                            </Form.Label>
                            <Col md="12">
                              <Form.Control  type="text" value={direccion} placeholder="Direccion" onChange={null} />
                            </Col>
                          </Form.Row>
                          <Form.Row className="mb-3">
                            <Col md="9">
                              <Form.Control type="text" value={localidad} placeholder="Localidad" onChange={null} />
                            </Col>
                            <Col md="3">
                              <Form.Control  type="number" value={codigopostal} placeholder="Codigo Postal" onChange={null} />
                            </Col>
                          </Form.Row>

                          <Form.Row className="mb-3">
                            <Form.Label column sm="6" className="obex-form-label">
                              Teléfono del Empleado
                            </Form.Label>
                            <Col md="6">
                              <Form.Control required type="phone" value={telefono} placeholder="Teléfono" onChange={null} />
                            </Col>
                          </Form.Row>
                          <Form.Row className="mb-3">
                            <Form.Label column sm="6" className="obex-form-label">
                              E-Mail del Empleado
                            </Form.Label>
                            <Col md="6">
                              <Form.Control type="email" value={email} placeholder="e-mail" onChange={null} />
                            </Col>
                          </Form.Row>

                          <Row className="mt-3">
                            <Col md="4" className="ml-auto">
                              {/*
                              <Button type="submit" className="btn-block obex-btn btn-green btn btn-primary" onClick={alta}  disabled={submitDisabled}>
                                Crear {success && <FontAwesomeIcon icon={faCheck} />}
                                {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
                              </Button>
                              */}
                            </Col>
                            
                            {error.length > 0 && (
                              <Alert variant='danger'>
                                {error}
                              </Alert>)}
                            {success.length > 0 && (
                              <Alert variant='success'>
                                {success}
                              </Alert>)}
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

              </Col>
                            
              </Row></>
          }
          
        </Col>
      </Row>
    </DashLayout>
  )
}

export default FxExchangeBenefit;