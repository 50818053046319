import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import DashRouters from '../../configs/routers/dashboard-routers-private.json';
import { Row, Col, Alert, Card, Button, Form, FormControl, Spinner, InputGroup  } from 'react-bootstrap';
import ObexRequestsHandler from '../../handlers/ObexRequestsHandler';
import ButtonSubmit from '../basicComponents/buttonSubmit';
import { useHistory,useParams } from 'react-router-dom';
import ObexProjectsRequestHandler from '../../handlers/ObexProjectsRequestsHandler';
import SessionService from '../../services/SessionsService';
import { getToday } from '../../services/TimeService';

type ClientsProps = {}


const ClientContent:FunctionComponent<ClientsProps> = (props) => {

  const history = useHistory();
  const id  = SessionService.IdUsuario;
  //const { } = props;
  const today =  getToday();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [from, setFrom] = useState(today);
  const [username, setUsername] = useState(String());
  const [email, setEmail] = useState(String());
  const [isValidated, setIsValidated ] = useState(false);
  const [stripeCustomerId, setSripeCustomerId ] = useState(String());
  const [stripeSignupCard, setStripeSignupCard ] = useState(String());
  const [stripeDefaultCard, setStripeDefaultCard ] = useState(String());
  const [isAdmin, setIsAdmin ] = useState(false);
  const [clients, setClients] = useState(Array());
  const [fecha_consulta, setFechaConsulta] = useState(getToday());
  const [stillLoaded, SetStillLoaded] = useState(false);

  const getClients = async (fecha) => {
    setLoading(true);
    SetStillLoaded(true);
    try {
      const { status,resultado,message} = await ObexProjectsRequestHandler.get(`/ws/employee_time_control.py/registered_events_today?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29&date=${fecha}`);
      console.log(resultado);
      if (!status) throw { message };
      const clientsList = resultado.sort((a, b) => a.id > b.id ? 1 : -1);

      setClients(clientsList);
    } catch (error) {
      setError(error.message || error)
      setLoading(false);
      SetStillLoaded(false);
    };
    SetStillLoaded(false);
  };

  const handleClientDetail = (event, client) => {
    event.preventDefault();
    event.stopPropagation();
    
    history.push(`/employees/${client.id}`);


  }
  const handleFrom = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setFechaConsulta(e.target.value); 
    getClients(e.target.value);
    console.log(e.target.value)

}   


  useEffect(()  => {
    getClients(fecha_consulta);
  },[]);


  return(
    <DashLayout sider={DashRouters} active="personal">
      <Row>
        <Col md="9" className="mx-auto">
          <Row>
            <Col>
            <h1 className="mb-3">Estados del día</h1>

                  

            </Col>
            <Col><Form.Control type="date" value={fecha_consulta} onChange={handleFrom}/></Col>
          </Row>
          <Row className="mb-3 border-bottom">
            <Col>Fecha</Col>
            <Col>Estado</Col>
            <Col md="2">Tipo</Col>
          </Row>

          {stillLoaded ?
            <Row className="h-100" >
              <Col md="2" className="mx-auto d-flex align-items-center" style={{ marginTop: '15em' }}>
                <Spinner animation="border"/>
              </Col>
            </Row>
            :
          <>
          {clients.map(client => { 
            return (
              <Row className="mb-3">
                <Col className="">
                  <span>{client.date}</span>
                </Col>
                <Col className="">
                <span>{client.time}</span>
                </Col>
                <Col md="2" className="">
                <span>{client.event_type}</span>
                </Col>
              </Row>
            )}
          )}
          </>
        }
        </Col>
      </Row>
    </DashLayout>
  )
}

export default ClientContent;