import {  RouteProps, Route, Redirect } from 'react-router-dom';
import LoginForm from '../components/loginForm/LoginForm';
import CountriesButton from '../components/functionalComponents/GetCountriesButton';
import React from 'react';
import SessionService from '../services/SessionsService';
import CreateSubcriptionContent from '../components/DashboardComponents/AdminComponents/createSubcription';
import VerifySignUp from '../components/Signup/VerifySignup';
import PluginsContent from '../components/pluginsPage/pluginsContent';
import PluginDetail from '../components/pluginsPage/pluginDetail';
import CronsContent from '../components/DashboardComponents/CronsComponents/cronsContent';
import ClientsContent from '../components/ClientsComponents/clientContent';
import ClientCommunications from '../components/ClientsComponents/clientCommunications';
import ClientSentCommunications from '../components/ClientsComponents/clientSentCommunications';
import ClientContent from '../components/ClientsComponents/ClientPersonalContent';
import Locations from '../components/ClientsComponents/Locations';
import ClientStatistics from '../components/ClientsComponents/clientStatistics';
import DatabaseContent from '../components/DatabaseComponents/databaseContent';
import DatabaseActives from '../components/DatabaseComponents/databaseActives';
import DatabaseRemoves from '../components/DatabaseComponents/databaseRemoves';
import AddonsContent from '../components/AddonsComponents/addonsAdd';
import AddonsAdd from '../components/AddonsComponents/addonsAdd';
import AddonsDelete from '../components/AddonsComponents/addonsDelete';
import AddonsConfiguration from '../components/AddonsComponents/addonsConfig';
import SupportConfiguration from '../components/SupportComponents/supportConfig';
import SupportAdd from '../components/SupportComponents/supportAdd';
import SupportDelete from '../components/SupportComponents/supportDelete';
import WebhooktList from '../components/WebhookComponents/webhookList';
import WebhookModify from '../components/WebhookComponents/webhookModify';
import WebhookDelete from '../components/WebhookComponents/webhookDelete';
import WebhooktAdd from '../components/WebhookComponents/webhookAdd';
import DomianList from '../components/DomainComponents/domainList';
import DomainAdd from '../components/DomainComponents/domainAdd';
import DomainDelete from '../components/DomainComponents/domainDelete';
import ControlAccountList from '../components/ControlAccountComponents/controlAccountList';
import ControlAccountAnnotate from '../components/ControlAccountComponents/controlAccountAnnotate';
import ServiceProjectBenefit from '../components/ServiceProjectsComponents/serviceProjectsBenefit';
import ServiceProjectsRepresentatives from '../components/ServiceProjectsComponents/serviceProjectsRepresentatives';
import Red101ObexBenefit from '../components/Red101obexComponents/red101obexBenefit';
import Red101ObexRepresentatives from '../components/Red101obexComponents/red101obexRepresentatives';
import FxExchangeConfiguration from '../components/fxExchangeComponents/FxExchangeConfiguration';
import FxExchangeBenefit from '../components/fxExchangeComponents/FxExchangeBenefit';
import ClientLicenseData from '../components/ClientsComponents/clientLicenseData';
import ClientBilling from '../components/ClientsComponents/clientBilling';
import ClientAccount from '../components/ClientsComponents/clientAccount';
import ClientAddons from '../components/ClientsComponents/clientAddons';
import ClientStorage from '../components/ClientsComponents/clientStorage';
import ClientProjects from '../components/ClientsComponents/clientProjects';
import ClientProjectPlugins from '../components/ClientsComponents/clientProjectsPlugins';
import ClientProjectUsers from '../components/ClientsComponents/clientProjectsUsers';
import ClientProjectUsersBlocked from '../components/ClientsComponents/clientProjectsUsersBlocked';
import PluginAdd from '../components/pluginsPage/pluginAdd';
import PlansResume from '../components/PlansAndPricing/PlansResume';
import SingupForm from '../components/Signup/signup';
import ChangePasswordSetting from '../components/DashboardComponents/SettingComponents/changePasswordSetting';
import ClientReceivedCommunications from '../components/ClientsComponents/clientReceivedCommunications';
import PaymentMethodContent from '../components/DashboardComponents/SettingComponents/PaymentMethods';
import BalanceContent from '../components/DashboardComponents/BalanceComponents/BalanceContent';



const Public = () => {return <h1>PUBLICO</h1>};
const isUser = ():boolean => SessionService.isUser();
const isAdmin = ():boolean => SessionService.isAdmin();

/**
 * Este bloque crea un componente que usaremos para rutas privadas
 * es decir, todas aquellas que se necesite estar logueado previamente
 * el funcionamiente lo que hace es comprobar si está loguead manda a la ruta, 
 * en caso de que no redirecciona al login.
 */
export const PrivateRoute: React.ComponentType<any> = ({
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
          isUser() ? ( // Comprobacion chorra(TOTAL NADIE USA ESTO)
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};


export const RootRoute: React.ComponentType<any> = ({
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
          isAdmin() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

// Rutas a las que todo el mundo tiene acceso
export const publicRoutes:Array<RouteProps> = [
  {
    path: '/',
    component: () => <LoginForm/>,
    exact: true
  },
  {
    path: '/login',
    component: () => <LoginForm/>,
    exact: true
  },
  {
    path: '/signup',
    component: (state, props) => {
      return (<SingupForm 
        extraProjectsPlan={state.location.state.extraProjectsPlan} 
        extraRequestsPlan={state.location.state.extraRequestsPlan} 
        planID={state.location.state.planID}
        totalPriceMonthly={state.location.state.totalPriceMonthly}
        totalPriceYear={state.location.state.totalPriceYear}
        nameProject={state.location.state.nameProject}
        pricePlanMonthly={state.location.state.pricePlanMonthly}
        pricePlanYear={state.location.state.pricePlanYear}
        txPriceMonthly={state.location.state.txPriceMonthly}
        txPriceYear={state.location.state.txPriceYear}
        projectPriceMonthly={state.location.state.projectPriceMonthly}
        projectPriceYear={state.location.state.projectPriceYear}
        projectDesc={state.location.state.projectDesc}
        txDesc={state.location.state.txDesc}
        newRate={state.location.state.newRate}
      />)
  },
    exact: true
  },
  {
    path: '/Plans',
    component: () => <PlansResume/>,
    exact: true
  } , {
    path: '/verify_signup',
    component: () => <VerifySignUp/>,
    exact:true
  },

]

//{(props) => <Dashboard {...props} isAuthed={true} />}

// Rutas privadas, necesitas estar logueado
export const privateRoutes:Array<RouteProps> = [
  {
    path: '/private',
    component: () => <ClientBilling/>
  },
  {
    path: '/employee/personal',
    component: () => <FxExchangeBenefit/>
  },
  {
    path: '/employee/status',
    component: () => <ClientContent/>
  },
  {
    path: '/employee',
    component: () => <ClientContent/>
  },
  {
    path: '/changepass',
    component: () => <ChangePasswordSetting/>
  },
  {
    path: '/notifications',
    component: () => <ClientReceivedCommunications/>
  }
]



// Rutas de administrador, debe de estar logueado y ser admin TODO ver esto
export const adminRoutes:Array<RouteProps> = [
  {
    path: '/admin',
    component: () => <CreateSubcriptionContent/>
  },  
  {
    path: '/locations',
    component: () => <Locations/>
  }, 
  {
    path: '/employees/add',
    component: () => <ClientStatistics/>
  },

  {
    path: '/employees/list',
    component: () => <ClientsContent/>
  },
  {
    path: '/employees',
    component: () => <ClientsContent/>
  },
  {
    path: '/employees:id/data',
    component: () => <ClientProjects/>
  },

  {
    path: '/communications/send',
    component: () => <ClientCommunications/>
  },

  {
    path: '/communications/sent',
    component: () => <ClientSentCommunications/>
  },
  {
    path: '/communications',
    component: () => <ClientSentCommunications/>
  },
  {
    path: '/communications:id/detail',
    component: () => <ClientProjects/>
  },
  
  {
    path: '/payment',
    component: () => <PaymentMethodContent/>
  },
  {
    path: '/balance',
    component: () => <BalanceContent/>
  },
  


]



const getProjectsFromState = (state) => {
  if (!state) return false;
  const projects = (((state.location || {}).state || {}).state || {}).projects || false;
  return projects;
}