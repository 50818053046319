import React, { FunctionComponent, useState, useRef, useEffect, Fragment } from 'react'; 
import { Container, Row, Col, CardGroup, CardDeck, Card, Button, Form, Spinner, Alert, Overlay, Tooltip } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import PlanCard from './PlanCard';
import PricingMenu from './PricingMenu';
import PricingFooter from './PrincingFooter';
import LogoIenova from '../../assets/img/ienova-gris.png';
import LogoNtt from '../../assets/img/ntt.png';
import LogoProsa from '../../assets/img/prosa-gris.png';
import LogoSura from '../../assets/img/sura-logo-gris.png';
import '../../assets/css/plansPricings.css';
import AddonsModal from './AddonModal';
import ObexRequestsHandler from '../../handlers/ObexRequestsHandler';
import { useHistory } from 'react-router-dom';
import { amountToEurPlain } from '../../services/AmountService';


import LogoAvanquia from '../../assets/img/logo-avanquia2x.png';
import LogoDreamslab from '../../assets/img/logo-dreamslab2x.png';
import LogoWaynnovate from '../../assets/img/logo-waynnovate2x.png';
import LogoNogales from '../../assets/img/logo-nogales2x.png';
import LogoPademobile from '../../assets/img/logo-pademobile2x.png';
import LogoLagloria from '../../assets/img/logo-lagloria2x.png';
import LogoPier from '../../assets/img/logo-pier2x.png';
import LogoLuegoLu3go from '../../assets/img/logo-luegoluego2x.png';

type PlansProps = {
  planID?: number
}

const PlansResume:FunctionComponent<PlansProps> = props => {
  const [loadplan2, setPlan2] = useState(0);
  const [subscriptions, setSubscriptions] = useState([]);
  const [show, setShow] = useState(false);
  const [signining, setSignining] = useState(false);
  const [mail, setMail] = useState('');
  const target = useRef(null);
  const history = useHistory();
  const [name, setName] = useState('');
  const [enterprise, setEnterprise] = useState('');
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('');
  const [workers, setWorkers] = useState('');
  const [ListCountries, setCountriesL] = useState([]);
  const [ListSubscription, setsubscriptionL] = useState([]);

  const [newRate, setNewRate] = useState(5);

  const [cargado, setCargado] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const getSubscriptions = async () => {
    const result = await ObexRequestHandler.get('/subscriptions', {'projects_type':1}, true);
    const { success, data } = result;
    if (success) {
      setSubscriptions(data);
      const subscriptionList = data.map(c => <>{c.id<39 ? <option value={c.id} selected={localityP == c.id }>{c.name}</option>: <option disabled={true} value={c.id} selected={localityP == c.id }>{c.name}</option>}</>
      );
      setsubscriptionL(subscriptionList);

    } else {
        console.error('ALGO HA IDO MAL')
    }
  }

  const handleSwitch = () => {
    setAnnualPayment(!annualPayment);
    updatePlanId()
  }


  const getCountries = async () => {
    try {
      if (!cargado){
      const result = (await ObexRequestsHandler.get('/countries')).data;
      setCargado(true);
      setCountries(result);
      const countriesList = result.map(c => <option value={c.country_code} selected={locality == c.country_code}>{c.country}</option>);
      setCountriesL(countriesList);
      }
    } catch (error) {
      console.error('ERROR FETCHING COUNTRIES ', error);
    }

  }
const [planIDF, setPlanIDF] = useState(37)
const [projName, setProjName] = useState("Basic")
const [AnnualPrice, setAnnualPrice] = useState(1)
const [MonthPrice, setMonthPrice] = useState(1)

const [exito, setEnviado] = useState(false);

  const [countries, setCountries] = useState([]);
  const [locality, setLocality] = useState('');

  const [localityP, setLocalityP] = useState('');

  const [pricePlanCal, setPricePlan] = useState(1);
  const [pricePlanCalYear, setPricePlanYear] = useState(1);

  const [destinatario, setMailDest] = useState("");
  const [nombredestinatario, setDestina] = useState("");
  const [annualPayment, setAnnualPayment] = useState(true);

  const handleSubmitSignup =  async (event) => {

    if (mail.includes("@") && mail.includes(".") && password!="" && name!=""){

    
    event.preventDefault();
    event.stopPropagation();
    setSignining(true);
    setDestina(name);
    setMailDest(mail);

    const result = await ObexRequestHandler.post('/sendToMail', {'subject':'Consulta acerca de Tempo','to':destinatario,'message':password});

    const adminmessage="Nombre: "+name+"<br><br>"+"Email: "+mail+"<br><br>"+ 
                        "Empleados: "+workers+"<br><br>"+"Empresa: "+enterprise+"<br><br>"+
                        "Teléfono: "+phone+"<br><br>"+"Mensaje: "+password+"<br><br>";

    const result2 = await ObexRequestHandler.post('/sendToMail', {'subject':'Consulta acerca de Tempo','to':"it@waynnovate.com",'message':adminmessage});

    setEnviado(true);
    setSignining(false);
    setTimeout(() => { 
      setEnviado(false)
    }, 6000);

    setName("");
    setEnterprise("");
    setPhone("");
    setWorkers("");
    setMail("");
    setPassword("");
  }
  else{
    setError("Nombre, correo electronico y mensaje son obligatorios, disculpe las molestias.");
    setTimeout(() => { 
      setError("")
    }, 6000);
  }

  };
  

  const updatePlanId = () => {
    var control = 0;
    subscriptions.map(cus => {
     
      if (control==0){
        if (!annualPayment) setPricePlan(cus.annual_price); else setPricePlan(cus.monthly_price);
        setPricePlanYear(cus.annual_price)
        setPlanIDF(cus.id)
        setProjName(cus.name)
        setAnnualPrice(cus.annual_price)
        setMonthPrice(cus.monthly_price)
        control=1;
      }
    })

  }


  const setPlanId = (event) => {
    subscriptions.map(cus => {
      if (cus.id == event.target.value) 
      {
        if (annualPayment) setPricePlan(cus.annual_price); else setPricePlan(cus.monthly_price);
        setPricePlanYear(cus.annual_price)
        setPlanIDF(cus.id)
        setProjName(cus.name)
        setAnnualPrice(cus.annual_price)
        setMonthPrice(cus.monthly_price)
      }
    })
  };

  const handleSubmitFreeTry = (event) => {

    console.log("NR " + newRate)

    const stateToSend = { 
      extraRequestsPlan: 0,
      extraProjectsPlan: 0,
      planID: planIDF,
      totalPriceMonthly: Number((MonthPrice + 0 + 0).toFixed(2)),
      pricePlanMonthly: MonthPrice,
      totalPriceYear:  Number(((AnnualPrice) + 0 + 0).toFixed(2)),
      pricePlanYear: AnnualPrice,
      nameProject: projName,
      projectPriceMonthly: 0,
      projectPriceYear: 0,
      txPriceMonthly: 0,
      txPriceYear: 0,
      txDesc: "",
      projectDesc: "",
      newRate: newRate


    }
    console.log('state to sneddd ', stateToSend)
    event.preventDefault();
    event.stopPropagation();
    history.push({
      pathname: '/signup',
      state: stateToSend
    })

  };


  const changeMail = (e) => {
    const newMail = e.target.value;
    setMail(newMail);
  }

  const changeCountry = (e) => {
    const newMail = e.target.value;
    setCountry(newMail);
  }
  const changePhone = (e) => {
    const newMail = e.target.value;
    setPhone(newMail);
  }
  const changeEnterprise = (e) => {
    const newMail = e.target.value;
    setEnterprise(newMail);
  }
  const changeWorkers = (e) => {
    const newMail = e.target.value;
    setWorkers(newMail);
  }
  const changeName = (e) => {
    const newMail = e.target.value;
    setName(newMail);
  }
  const handleNewRate = (e) => {
    const { value } = e.target;
    setNewRate(value);
  }
  const changeMessage = (e) => {
    const newPass = e.target.value;
    setPassword(newPass);
  }

  useEffect(() => {
    setNewRate(5);
    getCountries();
    getSubscriptions();
  }, []);
    
  return (
    <Fragment>



      <Container fluid className="plans_pricings">
        <PricingMenu
        active="pricing"
        />




        <div className="separator"></div>

        <Row className="planSelector">
        <span style={{paddingTop:'0.5em', paddingRight:'1em'}}><h4>Seleccione su plán</h4></span>
        <Form className="obex-form">

          <Form.Group controlId="formBasicEmail">
            <Form.Control className="planSelectorControl" required as="select" placeholder='Plan' custom onChange={(event) => { setPlanId(event) }}>
                  <option value="" selected disabled hidden>Basic</option>
                    {ListSubscription}
            </Form.Control>
          </Form.Group>

          </Form>

        </Row>
        {/*
        <Row className="justify-content-center">
          <span className="plan-price-deco-simulator">USD {pricePlanCal.toFixed(2)} por empleado y mes</span>
        </Row >
        */}
        <div className="separator-simulator"></div>
        <Row/>
        <Row/>
        <Row className="justify-content-center">

          <Col className="cardWorkers">
          {newRate < 1000 ? 
          (<p style={{minWidth: '100%'}}><span className="plan-price-deco-simulator-data-orange ">{newRate} Empleados</span>


          {
          <Form className="obex-form" style={{ scale: '1.4'  ,paddingLeft: '41%', paddingTop: '1em', color: '#FFF'}}>
                <Form.Row>

                    <Form.Check 
          
                      type="switch"
                      id="custom-switch"
                      checked={annualPayment}
                      label="Pago Anual"
                      onChange={handleSwitch}
                      className="obex-switch"
                    />

                </Form.Row>
              </Form>
          }
          </p>):
          
          (<p style={{minWidth: '100%'}}><span className="plan-price-deco-simulator-data-orange-advice">1000+ Empleados</span>
            <span className="plan-price-deco-simulator-advice-white">Pruebalo gratis por 30 días!</span>
          </p>)
          }




          
          </Col>
          





          <Col className="cardPrice">
            {newRate < 1000 ? 
          (<p style={{minWidth: '100%'}}>

          <span className="plan-price-deco-simulator-data-grey">{amountToEurPlain((pricePlanCal*newRate))}</span>
          <span className="plan-price-deco-simulator-data-month">USD/MES*</span>

          <Row className="mx-auto">

          {annualPayment ?
            <span className="plan-price-deco-simulator-data-grey-saving mx-auto">*Realizará un pago anual por importe de {amountToEurPlain(((pricePlanCalYear*newRate))*12)} USD.</span>
              
              :
            <span className="plan-price-deco-simulator-data-grey-saving mx-auto">*Usted puede ahorrar {amountToEurPlain(((pricePlanCal*newRate)-(pricePlanCalYear*newRate))*12)} USD al año seleccionando pago anual.</span>
              
          }



          {/*<span className="plan-price-deco-simulator-data-grey-saving ">Importe total anual {amountToEurPlain((pricePlanCal*newRate)*12)} Eur.</span>*/}
          </Row>
          </p>
          )
          
          :
          (<p style={{minWidth: '100%'}}>
            <span>
              <Row>
          <Button value={planIDF} className={"plans_button_offer text-center"} onClick={handleSubmitFreeTry}>
          Contratar
          </Button>
          </Row>
          </span>
          <Row>
            <span className="plan-price-deco-simulator-advice mx-auto">Mas de 1000 empleados? No hay problema</span>
          </Row>
          </p>
          )
            }
            
          </Col>
        
        </Row>
        <div className="separator-simulator"></div>
        <Row className="justify-content-center">
          <span style={{textAlign:'center'}} className="plan-price-deco-simulator">Cantidad de empleados (deslizar para calcular)</span>
        </Row >
        <Row/>
        <Form.Group className="workersSelector">

        <Form.Control type="range" ref={target} onClick={() => setShow(!show)} custom onChange={handleNewRate} value={newRate} max={1000} min={10} step={1}/>

        </Form.Group>
        <Row className="justify-content-center">
          <h1 className="title-page text-black my-5">Precios</h1>
        </Row>
        <Row className="">
          <div className="pricing-box-plans mx-auto">
            <CardGroup>
              {subscriptions.map(data => { return (<PlanCard data={data} planSelected={props.planID} activated={true} upgrade={false} newRate={newRate}/>);})}
            </CardGroup>
          </div>
        </Row>
        <Row>

        <span className="mid-separator"></span>

        </Row>

        <Row>

            <span className="plan-price-deco-simulator-data-transparent-saving mx-auto">*El precio por mes hace referencia al formato de pago anual.</span>

        </Row>


        <Row>
        <span className="separator"></span>
        </Row>
        <Row>

          
        </Row>
        <Row className="pricing-box-plans mx-auto py-5 pl-5 pr-5">
          
          <Col className="d-flex flex-column">
            <img className="logo-pricing my-auto ml-3 mr-3" src={LogoNogales}/>
          </Col>
          <Col className="d-flex flex-column">
            <img className="logo-pricing my-auto ml-3 mr-3" src={LogoLagloria}/>
          </Col>
          <Col className="d-flex flex-column">
            <img className="logo-pricing my-auto ml-3 mr-3" src={LogoPier}/>
          </Col>

          <Col className="d-flex flex-column">
            <img className="logo-pricing my-auto ml-3 mr-3" src={LogoDreamslab}/>
          </Col>
          <Col className="d-flex flex-column">
            <img className="logo-pricing my-auto ml-3 mr-3" src={LogoPademobile}/>
          </Col>
          <Col className="d-flex flex-column">
            <img className="logo-pricing my-auto ml-3 mr-3" src={LogoWaynnovate}/>
          </Col>
          <Col className="d-flex flex-column">
            <img className="logo-pricing my-auto ml-3 mr-3" src={LogoLuegoLu3go}/>
          </Col>

          <Col className="d-flex flex-column">
            <img className="logo-pricing my-auto ml-3 mr-3" src={LogoAvanquia}/>
          </Col>
        </Row>
    {/*
        <Row>
          <Col className="contact-plan">

          <Row><span className="contact">Contacto</span></Row>
          <Row><span className="direction">18006 Jose Luis Perez Pujadas, Oficina B43 Edificio Forum</span></Row>

          </Col>
          <Col className="form-plan">
          
          <Form className="obex-form">
                  Nombre
                  <Form.Group controlId="formBasicName">
                    <Form.Control required placeholder="Ingrese su nombre" onChange={changeName} value={name}/>
                  </Form.Group>
                  Nombre de la empresa
                  <Form.Group controlId="formBasicEnterprise">
                    <Form.Control required placeholder="Ingrese el nombre de la empresa" onChange={changeEnterprise} value={enterprise}/>
                  </Form.Group>
                  Teléfono
                  <Form.Group controlId="formBasicPhone">
                    <Form.Control required type="phone" placeholder="Ingrese su teléfono" onChange={changePhone} value={phone}/>
                  </Form.Group>
                  Seleccione su País
                  <Form.Group controlId="formBasicCountry">
                    <Form.Control required as="select" placeholder='Pais' custom onChange={null}>
                          <option value="" selected disabled hidden>Seleccione su País</option>
                            {ListCountries}
                    </Form.Control>
                  </Form.Group>
                  Cantidad de empleados
                  <Form.Group controlId="formBasicWorkers">
                    <Form.Control placeholder="Cantidad de empleados" onChange={changeWorkers} value={workers}/>
                  </Form.Group>
                  Email
                  <Form.Group controlId="formBasicEmail">
                    <Form.Control required type="email" placeholder="Ingrese email" onChange={changeMail} value={mail}/>
                  </Form.Group>
                  Mensaje
                  <Form.Group controlId="formBasicMessage">

                  <Form.Control required as="textarea" rows={3} placeholder="" onChange={changeMessage} value={password} />
                  </Form.Group>
                  <Button type="submit" className="my-2 plans_button  btn-block button_blured" onClick={handleSubmitSignup} disabled={signining}>
                    {signining ? "Enviando" : "Enviar"}
                    {signining && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
                  </Button>
                  {error.length > 0 && (
                    <Alert variant='danger'>
                      {error}
                    </Alert>)}
                    {exito && (
                    <Alert variant='success'>
                      Muchas gracias por ponerse en contacto con nosotros {nombredestinatario}, recibirá respuesta en el correo {destinatario}.
                    </Alert>)}
            </Form>
          
          </Col>
                    
        </Row>
        */}
      </Container>
      <PricingFooter/>
    </Fragment>
  );
}

export default PlansResume;