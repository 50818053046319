import React, { FunctionComponent, useState, useEffect, Fragment } from 'react'; 
import { Container, Row, Col, Card, Modal, Form, Button, Tooltip } from 'react-bootstrap';
import AddonsModal from './AddonModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck,faInfoCircle  } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

type PlanCardProps = {
  data: any,
  planSelected?: number,
  activated: boolean,
  upgrade: boolean,
  newRate: number
};


function routeSignUp() {
  const path = '/signup';
  window.location.href=path
}

const handleSApply = async (event) => {
  event.preventDefault();
  event.stopPropagation();
  routeSignUp();
};

const PlanCard:FunctionComponent<PlanCardProps> = props => {

  const activated = props.activated;
  const upgrade = props.upgrade;
  const newRate = props.newRate;
  const [loadplan, setPlan] = useState(0);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [pricePlan, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const [footer, setFooter] = useState('');
  const [estilo, setEstilo] = useState('text-center planCard');
  const [botonEstilo, setBotonEstilo] = useState({});
  const [botonTipo, setBotonTipo] = useState('plans_button text-center');
  const [style , setStyle] = useState('')
  const [activeLimit , setactiveLimit] = useState(11)


  const [Line0 , setLine0] = useState('')
  const [Line1 , setLine1] = useState('')
  const [Line2 , setLine2] = useState('')
  const [Line3 , setLine3] = useState('')
  const [Line4 , setLine4] = useState('')
  const [Line5 , setLine5] = useState('')
  const [Line6 , setLine6] = useState('')
  const [Line7 , setLine7] = useState('')
  const [Line8 , setLine8] = useState('')
  const [Line9 , setLine9] = useState('')
  const [Line10 , setLine10] = useState('')

  const [iconCheck, setIconCheck] = useState('')
  const [IconInfo, setIconInfo] = useState('')

  const handleModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setPlan(parseInt(event.target.value));
    if (event.target.value==4) window.location.replace('https://www.101obex.com/#comp-jxad0a3m');
    else handleShow();
  };

  const addPrice = () => {
    if (props.data.monthly_price == 0){
      setPrice('Free');
    }else if(props.data.annual_price == null){
      setPrice('Contact Sales');
    }else{
      setPrice(props.data.annual_price);
      console.log(props.data);
    }
  }
  const history = useHistory();
  const [pricePlanMontly, setPricePlanMontly] = useState(props.data.monthly_price);
  const [selectedProject, setProject] = useState(0);
  const [selectedTx, setTx] = useState(0);
  const [projectPlanPriceMonthly, setProjectPriceMonthly] = useState(0);
  const [projectPlanPriceYear, setProjectPriceYear] = useState(0);
  const [txPlanPriceMonthly, setTxPriceMonthly] = useState(0);
  const [txPlanPriceYear, setTxPriceYear] = useState(0);
  const [txDesc, setTxDesc] = useState('');
  const [projectDesc, setProjectDesc] = useState('');


  const handleSubmit = (event) => {


    const stateToSend = { 
      extraRequestsPlan: selectedTx,
      extraProjectsPlan: selectedProject,
      planID: props.data.id,
      totalPriceMonthly: Number((pricePlanMontly + projectPlanPriceMonthly + txPlanPriceMonthly).toFixed(2)),
      pricePlanMonthly: pricePlanMontly,
      totalPriceYear:  Number((props.data.annual_price + projectPlanPriceYear + txPlanPriceYear).toFixed(2)),
      pricePlanYear: props.data.annual_price,
      nameProject: props.data.name,
      projectPriceMonthly: projectPlanPriceMonthly,
      projectPriceYear: projectPlanPriceYear,
      txPriceMonthly: txPlanPriceMonthly,
      txPriceYear: txPlanPriceYear,
      txDesc: txDesc,
      projectDesc: projectDesc,
      newRate: newRate


    }
    console.log('state to sneddd ', stateToSend)
    event.preventDefault();
    event.stopPropagation();
    history.push({
      pathname: '/signup',
      state: stateToSend
    })

  };



  useEffect(() => {
    if (props.planSelected==props.data.id){
      setPlan(props.planSelected);

      handleShow();
    }
    setLine0("Marcar asistencia desde el teléfono o tablet")
    setLine1("Reporte de marcas con foto")
    setLine2("Monitorización de Geocalización por GPS")
    
    setLine3("Registro sin QR")
    setLine4("Acceso a Servicio de Soporte")
    setLine5("Mensajes a empleados")
    setLine6("Gestión de Ubicaciones")
    setLine7("Funcionamiento OFFLINE")

    setLine8("Multiples usuarios y permisos")
    setLine9("Adjuntar certificados de faltas")
    setLine10("Reporte masivo de horas y asistencia")

    if (props.data.name=="Basic") {setDescription("The Developer plan is the perfect place to start designing, developing, and testing APIs.");
        setFooter(" *in-app purchase. Taxes and fees may apply.");
        setStyle("card-title1")
        setactiveLimit(5)
        
  
      }
    if (props.data.name=="Premium") {setDescription("The Startup plan helps to transform your idea into a project.");
    setFooter("*per month, when billed monthly. €44.92, per month, when billed yearly. Taxes and fees may apply.");
    setStyle("card-title2")
    setactiveLimit(7)
  }
    if (props.data.name=="Premium Plus") {setDescription("The Business plan helps you rapidly scale-up your organization and manage the entire API workflow, all in one place.");
    setFooter("*per month, when billed monthly. €136.59, per month, when billed yearly. Taxes and fees may apply.");
    setStyle("card-title3")
    setactiveLimit(9)
  }
    if (props.data.name=="Enterprises") {setDescription("The Enterprise plan is our enterprise-grade offering for very large businesses or those in highly regulated industries.");
    setStyle("card-title4")
    setactiveLimit(11)
  }

  if (activated) {
    if (upgrade) setEstilo("text-center planCardUpdate"); else setEstilo("text-center planCard");
    setBotonEstilo({});        
    setBotonTipo("plans_button text-center")     
} else {
  if (upgrade) setEstilo("text-center planCardUpdate inactive"); else setEstilo("text-center planCard inactive");
  setBotonEstilo({ backgroundColor: '#474747', borderColor: '#474747' })
  setBotonTipo("plans_button_deactivated text-center");
}

    addPrice();
  }, []);

  return(
    
    <Card className={estilo}>
      
      {/*
      {(loadplan > 0 && activated) && 
      <AddonsModal 
        show={show}
        handleClose={handleClose}
        nameProject={props.data.name}
        planID={loadplan}
        priceMonthly={props.data.monthly_price}
        priceYear={props.data.annual_price}
        upgrade={upgrade}
      />} 
      */}
      <Card.Body className="d-flex flex-column">
        <Card.Title className={style}> 
          <span className={style} >{(props.data.name).toUpperCase()}</span>
        </Card.Title>
 
        <Card.Text  className={' plan-price-back align-items-up justify-content-center'} >
          <span className={"plan-price-deco"}>USD </span><span className={"plan-price"}>{parseFloat(pricePlan).toFixed(2)}</span><span className={"plan-price-deco"}> por</span>
        </Card.Text>
        <Card.Text className={'plan-price-deco-bottom'}>empleado / mes*</Card.Text>
        <Card.Text className={'plan-price-deco-includes'}>INCLUYE</Card.Text>
        <Card.Text className="plan-descrip-request mt-auto"></Card.Text>

        
        <Card.Text className={(activeLimit<1) ? "plan-descrip-features-inactive mt-auto":"plan-descrip-features-active mt-auto" }>{activeLimit<1 ? <span className="plan-descrip-features-space"/> : <FontAwesomeIcon icon={faCheck}/> } {Line0} {activeLimit<1 ? <span className="plan-descrip-features-space"/> : <a>{/*<FontAwesomeIcon className="plan-descrip-features-info" icon={faInfoCircle}/> <Tooltip style={{marginLeft : '20em', marginTop : '-2em'}} title="El telefono movil o tableta debe tener camara frontal" id={'info2'}><a><FontAwesomeIcon icon={faInfoCircle}/></a></Tooltip>*/} </a>}</Card.Text>
        <Card.Text className={(activeLimit<2) ? "plan-descrip-features-inactive mt-auto":"plan-descrip-features-active mt-auto" }>{activeLimit<2 ? <span className="plan-descrip-features-space"/> : <FontAwesomeIcon icon={faCheck}/> } {Line1} {activeLimit<2 ? <span className="plan-descrip-features-space"/> : <a>{/*<FontAwesomeIcon className="plan-descrip-features-info" icon={faInfoCircle}/> <Tooltip style={{marginLeft : '20em', marginTop : '-2em'}} title="Incluye hora, fecha y geolocalización." id={'info2'}><a><FontAwesomeIcon icon={faInfoCircle}/></a></Tooltip>*/}</a>}</Card.Text>
        <Card.Text className={(activeLimit<3) ? "plan-descrip-features-inactive mt-auto":"plan-descrip-features-active mt-auto" }>{activeLimit<3 ? <span className="plan-descrip-features-space"/> : <FontAwesomeIcon icon={faCheck}/> } {Line2} {activeLimit<3 ? <span className="plan-descrip-features-space"/> : <a>{/*<FontAwesomeIcon className="plan-descrip-features-info" icon={faInfoCircle}/> <Tooltip style={{marginLeft : '20em', marginTop : '-2em'}} title="La Geolocalizacion funciona en moviles y tablets." id={'info2'}><a><FontAwesomeIcon icon={faInfoCircle}/></a></Tooltip>*/}</a>}</Card.Text>
        <Card.Text className={(activeLimit<4) ? "plan-descrip-features-inactive mt-auto":"plan-descrip-features-active mt-auto" }>{activeLimit<4 ? <span className="plan-descrip-features-space"/> : <FontAwesomeIcon icon={faCheck}/> } {Line3} {activeLimit<4 ? <span className="plan-descrip-features-space"/> : <a>{/*<FontAwesomeIcon className="plan-descrip-features-info" icon={faInfoCircle}/> <Tooltip style={{marginLeft : '20em', marginTop : '-2em'}} title="Recibira notificaciones de tardanza, inasistencias, fuera de zona y fraude en su correo electronico en minutos" id={'info2'}><a><FontAwesomeIcon icon={faInfoCircle}/></a></Tooltip>*/}</a>}</Card.Text>
        <Card.Text className={(activeLimit<5) ? "plan-descrip-features-inactive mt-auto":"plan-descrip-features-active mt-auto" }>{activeLimit<5 ? <span className="plan-descrip-features-space"/> : <FontAwesomeIcon icon={faCheck}/> } {Line4} {activeLimit<5 ? <span className="plan-descrip-features-space"/> : <a>{/*<FontAwesomeIcon className="plan-descrip-features-info" icon={faInfoCircle}/> <Tooltip style={{marginLeft : '20em', marginTop : '-2em'}} title="A diferencis de los lectores de huella dactilar, no hay tiempo de entrenamiento. Evita el contacto de superficie de varias personas" id={'info2'}><a><FontAwesomeIcon icon={faInfoCircle}/></a></Tooltip>*/}</a>}</Card.Text>
        <Card.Text className={(activeLimit<6) ? "plan-descrip-features-inactive mt-auto":"plan-descrip-features-active mt-auto" }>{activeLimit<6 ? <span className="plan-descrip-features-space"/> : <FontAwesomeIcon icon={faCheck}/> } {Line5} {activeLimit<6 ? <span className="plan-descrip-features-space"/> : <a>{/*<FontAwesomeIcon className="plan-descrip-features-info" icon={faInfoCircle}/> <Tooltip style={{marginLeft : '20em', marginTop : '-2em'}} title="Si un empreado se olvida de fichar, el administrador puede ingresar o modificar sus marcas manualmente" id={'info2'}><a><FontAwesomeIcon icon={faInfoCircle}/></a></Tooltip>*/}</a>}</Card.Text>
        <Card.Text className={(activeLimit<7) ? "plan-descrip-features-inactive mt-auto":"plan-descrip-features-active mt-auto" }>{activeLimit<7 ? <span className="plan-descrip-features-space"/> : <FontAwesomeIcon icon={faCheck}/> } {Line6} {activeLimit<7 ? <span className="plan-descrip-features-space"/> : <a>{/*<FontAwesomeIcon className="plan-descrip-features-info" icon={faInfoCircle}/> <Tooltip style={{marginLeft : '20em', marginTop : '-2em'}} title="No importa si se interrumpe la conexion a internet, el empleado puede marcar y la informacion se sincroniza cuando el equipo recupera la conexión" id={'info2'}><a><FontAwesomeIcon icon={faInfoCircle}/></a></Tooltip>*/}</a>}</Card.Text>
        <Card.Text className={(activeLimit<8) ? "plan-descrip-features-inactive mt-auto":"plan-descrip-features-active mt-auto" }>{activeLimit<8 ? <span className="plan-descrip-features-space"/> : <FontAwesomeIcon icon={faCheck}/> } {Line7} {activeLimit<8 ? <span className="plan-descrip-features-space"/> : <a>{/*<FontAwesomeIcon className="plan-descrip-features-info" icon={faInfoCircle}/> <Tooltip style={{marginLeft : '20em', marginTop : '-2em'}} title="Desde la web de administración se pueden enviar mensajes a los empleados, los mensajes se visualizan al momento de marcar la entrada o salida." id={'info2'}><a><FontAwesomeIcon icon={faInfoCircle}/></a></Tooltip>*/}</a>}</Card.Text>
        <Card.Text className={(activeLimit<9) ? "plan-descrip-features-inactive mt-auto":"plan-descrip-features-active mt-auto" }>{activeLimit<9 ? <span className="plan-descrip-features-space"/> : <FontAwesomeIcon icon={faCheck}/> } {Line8} {activeLimit<9 ? <span className="plan-descrip-features-space"/> : <a>{/*<FontAwesomeIcon className="plan-descrip-features-info" icon={faInfoCircle}/> <Tooltip style={{marginLeft : '20em', marginTop : '-2em'}} title="Defina los usuarios y roles que necesite para su equipo administrativo" id={'info2'}><a><FontAwesomeIcon icon={faInfoCircle}/></a></Tooltip>*/}</a>}</Card.Text>
     <Card.Text className={(activeLimit<10) ? "plan-descrip-features-inactive mt-auto":"plan-descrip-features-active mt-auto" }>{activeLimit<10 ? <span className="plan-descrip-features-space"/> : <FontAwesomeIcon icon={faCheck}/> } {Line9} {activeLimit<10 ? <span className="plan-descrip-features-space"/> : <a>{/*<FontAwesomeIcon className="plan-descrip-features-info" icon={faInfoCircle}/> <Tooltip style={{marginLeft : '20em', marginTop : '-2em'}} title="Puede adjuntar faltas medicas y otros tipos d certificaciones de empleados" id={'info2'}><a><FontAwesomeIcon icon={faInfoCircle}/></a></Tooltip>*/}</a>}</Card.Text>
    <Card.Text className={(activeLimit<11) ? "plan-descrip-features-inactive mt-auto":"plan-descrip-features-active mt-auto" }>{activeLimit<11 ? <span className="plan-descrip-features-space"/> : <FontAwesomeIcon icon={faCheck}/> } {Line10} {activeLimit<11 ? <span className="plan-descrip-features-space"/> : <a>{/*<FontAwesomeIcon className="plan-descrip-features-info" icon={faInfoCircle}/> <Tooltip style={{marginLeft : '20em', marginTop : '-2em'}} title="No pierda tiempo, obtenga un reporte general para todos los empleadosen el rango de fechas que lo necesita." id={'info2'}><a><FontAwesomeIcon icon={faInfoCircle}/></a></Tooltip>*/}</a>}</Card.Text>
        <Card.Text className="plan-descrip-request mt-auto"></Card.Text>
        <div className="text-center">
          {(props.data.id==4) ?
          <Button  active={!activated} disabled={!activated} style={botonEstilo} value={props.data.id} className={botonTipo} onClick={handleModal}>
            Contact Sales
          </Button>
          :  
          <>
          {(props.data.id<39) ?
          <Button  active={!activated} disabled={!activated} style={botonEstilo} value={props.data.id} className={botonTipo} onClick={handleSubmit}>
          Contratar
        </Button>
        :
        <Button  active={false} disabled={true} style={botonEstilo} value={props.data.id} className={botonTipo} onClick={handleSubmit}>
        Contratar
      </Button>}
        </>
        }
        <Card.Text className="plan-descrip-request mt-auto"></Card.Text>
        <Card.Text className="plan-descrip-request mt-auto"></Card.Text>
        <Card.Text className="plan-descrip-request mt-auto"></Card.Text>
        </div>
      </Card.Body>
    </Card>

      

  );
      
};

{/* <p className="plan-descrip active">Ideal for individual develpoers</p>
  <p className="plan-descrip active">Developes with a full access</p>
  <p className="plan-descrip active">Begin collaborating with your team</p>
  <p className="plan-descrip active">Accelerate development with powerfull tools</p>
  <p className="plan-descrip active">Authenticate users with SSO</p> */}


export default PlanCard;