import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import { Row, Col, Alert, Card, Button, Form, FormControl, Spinner, InputGroup  } from 'react-bootstrap';
import ObexRequestsHandler from '../../handlers/ObexRequestsHandler';
import ButtonSubmit from '../basicComponents/buttonSubmit';
import { useHistory,useParams } from 'react-router-dom';
import ObexProjectsRequestHandler from '../../handlers/ObexProjectsRequestsHandler';
import SessionService from '../../services/SessionsService';
import { getToday } from '../../services/TimeService';
import { setMaxListeners } from 'process';
import AddTxSelModal from '../Modals/addTxModalSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

type ClientsProps = {}


const ClientProjects:FunctionComponent<ClientsProps> = (props) => {

  const history = useHistory();
  const { id } = useParams();
  //const { } = props;
  const today =  getToday();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [from, setFrom] = useState(today);
  const [username, setUsername] = useState(String());
  const [email, setEmail] = useState(String());
  const [isValidated, setIsValidated ] = useState(false);
  const [stripeCustomerId, setSripeCustomerId ] = useState(String());
  const [stripeSignupCard, setStripeSignupCard ] = useState(String());
  const [stripeDefaultCard, setStripeDefaultCard ] = useState(String());
  const [isAdmin, setIsAdmin ] = useState(false);
  const [clients, setClients] = useState(Array());
  const [Locations, setLocations] = useState(Array());
  const [fecha_consulta, setFechaConsulta] = useState(getToday());
  const [active, setActivo] = useState(true);
  const [sending, setSending] = useState(false);
  const [canUpdate, setCanUpdate] = useState(true);
  const [Mail, setMail] = useState('');
  const [Nombre, setName] = useState('');
  const [Direccion, setDireccion] = useState('');
  const [activeUpdate, setActiveUpdate] = useState(false);
  const [habilitado, setHabilitado] = useState(true);
  const [habilitadoL, setHabilitadoL] = useState(true);
  const [habilitadoQ, setHabilitadoQ] = useState(true);
  const [activating,setActivating] = useState(false);
  const [localizating,setLocalizating] = useState(false);
  const [QRblezating,setQRblezating] = useState(false);
  const [stillLoaded, SetStillLoaded] = useState(false);
  const [activeEmployees, setActivos] = useState(0);
  const [localizable, setLocalizable] = useState(false);
  const [QRble, setQRble] = useState(false);
  const [maxEmployees, setMaximos] = useState(0);
  const [canActivate , setCanActivate] = useState(false);
  const [addingTx, setAddingTx ] = useState(false);
  const [locationOptions, setOptionLocation] = useState(Array());
  const [locationDefault, setDefaultLocation] = useState(0);
  const [ActualLocalization,setActualLocalization] = useState(0);
  const [AbledLocations, setAbledLocations] = useState(Array());
  const [LocationtoAdd, setLocationtoAdd] = useState(0);
  const [hoursToday, setHoursToday] = useState('0h 0m');
  const [hoursWeek, setHoursWeek] = useState('0h 0m');
  const [hoursMonth, setHoursMonth] = useState('0h 0m');

  const whenClosing = () => {
    setAddingTx(false);
  }

  const getClients = async (fecha) => {
    setLoading(true);
    SetStillLoaded(true);
    try {
      const { status,resultado,message, actividad} = await ObexProjectsRequestHandler.get(`/ws/employee_time_control.py/employee_data?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29&user=${id}&date=${fecha}`);
      console.log("RESULTADO");
      console.log(resultado);
      console.log(actividad)

      if (actividad){

        var horas_hoy = `${Math.floor(actividad.total_today / 3600)}h ${Math.floor((actividad.total_today % 3600) / 60)}m`
        var horas_semana = `${Math.floor(actividad.total_week / 3600)}h ${Math.floor((actividad.total_week % 3600) / 60)}m`
        var horas_mes = `${Math.floor(actividad.total_month / 3600)}h ${Math.floor((actividad.total_month % 3600) / 60)}m`

        setHoursToday(horas_hoy);
        setHoursWeek(horas_semana);
        setHoursMonth(horas_mes);
      }

      if (!status) throw { message };
      const clientsList = resultado.sort((a, b) => a.id > b.id ? 1 : -1);

      setClients(clientsList);
    } catch (error) {
      setError(error.message || error)
      setLoading(false);
      SetStillLoaded(false);
    };
    SetStillLoaded(false);
  };

  

  const handleName = (e) => {
    const newname = e.target.value;
    
    if (newname!=SessionService.ClientConsult["nombre"]) setActiveUpdate(true); else setActiveUpdate(false);
    setName(newname);


  }

  const handleDireccion = (e) => {
    const newdireccion = e.target.value;
    
    if (newdireccion!=SessionService.ClientConsult["estado"]) setActiveUpdate(true); else setActiveUpdate(false);
    setDireccion(newdireccion);


  }
  const handleMail = (e) => {
    const newmail = e.target.value;
    
    if (newmail!=SessionService.ClientConsult["email"]) setActiveUpdate(true); else setActiveUpdate(false);
    setMail(newmail);


  }


const getAbledLocations = async (LocationsList) =>{

  var locationsAbled = new Array();


  SessionService.ClientConsult["location"].forEach(elemento => {

    LocationsList.forEach(element => {
      
      if (elemento==element.id) {

                locationsAbled.push({'name' : element.name, 'id':element.id, 'direccion':element.address});
              }
      
    });

    setAbledLocations(locationsAbled);

  });

}

  const getLocations = async () => {
    setLoading(true);
    SetStillLoaded(true);
    try {
      const {status, resultado, message } = await ObexProjectsRequestHandler.get(`/ws/employee_time_control.py/get_locations?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29`);

      if (!status) throw { message };
      const LocationsList = resultado.sort((a, b) => a.id > b.id ? 1 : -1);

      var defaultLocation = -1;

      LocationsList.forEach(element => {
        if (defaultLocation==-1) defaultLocation=element.id
      });

      if (defaultLocation==-1) defaultLocation=0;

      setDefaultLocation(defaultLocation);
      setLocationtoAdd(defaultLocation);

      const optionLocation = resultado.map(value => <option value={value.id}>{value.name}</option>)
      setOptionLocation(optionLocation);

      setLocations(LocationsList);

      getAbledLocations(LocationsList);

    } catch (error) {
      setError(error.message || error)
      setLoading(false);
      SetStillLoaded(false);
    };
    SetStillLoaded(false);
  };




  const get_AffiliateData = async(): Promise<any> => {
    var result = 100;
    result = (await ObexRequestsHandler.get(`/get_max_employees/${SessionService.IdUsuario}`)).data || 100;
    console.log("RESULT "+result)
    const { status,resultado, message} = await ObexProjectsRequestHandler.get(`/ws/employee_time_control.py/get_employees?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29`);
    console.log(resultado);
    var activos=0;
    resultado.forEach(p => {
      if (p.activo) activos+=1;
    })

    setActivos(activos);
    setMaximos(result);

    if (activos<result) {
      setCanActivate(true);
      return true;
    
    }

    return false;

  };




  const HandleActivate = async (e) =>{
    setActivating(true);
    setHabilitado(false);
    const canAct = await get_AffiliateData();
    console.log(canAct);
    if (canAct || active){

    if (active)
      {
        
        const { status,resultado,message} = await ObexProjectsRequestHandler.get(`/ws/cuentas.py/parar?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29&telefono=${SessionService.ClientConsult["nick"]}`);

        if (status) setActivo(false);
      }
    else 
      {
        const { status,resultado,message} = await ObexProjectsRequestHandler.get(`/ws/cuentas.py/activar?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29&telefono=${SessionService.ClientConsult["nick"]}`);
        if (status) setActivo(true);
      }
      setActivating(false);
      setHabilitado(true);

    } else {
      setAddingTx(true);
    }
      
    }

    const handleActualize = async (e) =>{
      e.preventDefault();
      e.stopPropagation();
      setSending(true);
      setCanUpdate(false);
      setActiveUpdate(false);
      try{

        const {status, resultado, message } = 
        await ObexProjectsRequestHandler.get(
            `/ws/admin.py/actualizardatosusuarios?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29&telefono=${SessionService.ClientConsult["nick"]}&e_mail=${Mail}&nombre=${Nombre}&estado=${Direccion}`);
          
        if (status){
          SessionService.ClientConsult["nombre"] = Nombre;
          SessionService.ClientConsult["email"] = Mail;
          SessionService.ClientConsult["estado"] = Direccion;
        } else {
          setName(SessionService.ClientConsult["nombre"])
          setMail(SessionService.ClientConsult["email"]);
          setDireccion(SessionService.ClientConsult["estado"]);
        }

      } catch (error) {
        setError(error.message || error)
        setLoading(false);
        SetStillLoaded(false);
        setLocalizating(false);
        setHabilitadoL(true);
        setSending(false);
        setCanUpdate(true);
        setActiveUpdate(true);
        
      };
      setSending(false);
      setCanUpdate(true);
    }

    const HandleSelectNewLocation = async (e) =>{
    
      setLocationtoAdd(e.target.value);
      setActualLocalization(e.target.value);

    }

    const HandleChangeLocation = async (e) =>{

      
      e.preventDefault();
      e.stopPropagation();

      setLocalizating(true);
      setHabilitadoL(false);

      const nuevaLocalizacion = LocationtoAdd;

      try {
      
      var employee_location = SessionService.ClientConsult["location"];

      employee_location.push(nuevaLocalizacion)

      const {status, resultado, message } = 
        await ObexProjectsRequestHandler.get(
            `/ws/employee_time_control.py/add_employee_location?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29&telefono=${SessionService.ClientConsult["nick"]}&location_id=${nuevaLocalizacion}`);
          
            if (status)  {
              
              SessionService.ClientConsult["location"]=employee_location;
              getLocations();
            }

          } catch (error) {
            setError(error.message || error)
            setLoading(false);
            SetStillLoaded(false);
            setLocalizating(false);
            setHabilitadoL(true);
          };
          
      setLocalizating(false);
      setHabilitadoL(true);
        
      }





    const HandleLocalice = async (e) =>{

      setLocalizating(true);
      setHabilitadoL(false);
     
      if (localizable)
        {

          const { status,resultado,message} = await ObexProjectsRequestHandler.get(`/ws/admin.py/actualizardatosusuarios?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29&telefono=${SessionService.ClientConsult["nick"]}&location_to_check=False`);


          if (status) setLocalizable(false);
        }
      else 
        {
          const { status,resultado,message} = await ObexProjectsRequestHandler.get(`/ws/admin.py/actualizardatosusuarios?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29&telefono=${SessionService.ClientConsult["nick"]}&location_to_check=True`);
          if (status) setLocalizable(true);
        }
        setLocalizating(false);
        setHabilitadoL(true);
  
        
      }

      const HandleQRble = async (e) =>{

        setQRblezating(true);
        setHabilitadoQ(false);
  
        if (QRble)
          {
  
            const { status,resultado,message} = await ObexProjectsRequestHandler.get(`/ws/admin.py/actualizardatosusuarios?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29&telefono=${SessionService.ClientConsult["nick"]}&qr_to_check=False}`);
  
  
            if (status) setQRble(false);
          }
        else 
          {
            const { status,resultado,message} = await ObexProjectsRequestHandler.get(`/ws/admin.py/actualizardatosusuarios?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29&telefono=${SessionService.ClientConsult["nick"]}&qr_to_check=True`);
            if (status) setQRble(true);
          }
          setQRblezating(false);
          setHabilitadoQ(true);
    
          
        }




const handleEraseEmployeeLocation = async (event,client) => {
  event.preventDefault();
  event.stopPropagation();


  setLocalizating(true);
  setHabilitadoL(false);

  const nuevaLocalizacion = client.id;
  console.log("A BORRAR " + nuevaLocalizacion);
  
  try {
  
  var employee_location = SessionService.ClientConsult["location"];
  var nuevo_employee_location = new Array();

  employee_location.forEach(element => {
    if (element!=nuevaLocalizacion) nuevo_employee_location.push(element)
  });

  console.log("NUEVO " + nuevo_employee_location);

  const {status, resultado, message } = 
    await ObexProjectsRequestHandler.get(
        `/ws/employee_time_control.py/del_employee_location?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29&telefono=${SessionService.ClientConsult["nick"]}&location_id=${nuevaLocalizacion}`);
      
        if (status)  {
          
          SessionService.ClientConsult["location"]=nuevo_employee_location;
          getLocations();
        }

      } catch (error) {
        setError(error.message || error)
        setLoading(false);
        SetStillLoaded(false);
        setLocalizating(false);
        setHabilitadoL(true);
      };

      
  setLocalizating(false);
  setHabilitadoL(true);
    
  





}


  const handleClientDetail = (event, client) => {
    event.preventDefault();
    event.stopPropagation();
    history.push(`/employees/${client.id}`);
  }
  const handleFrom = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setFechaConsulta(e.target.value); 
    getClients(e.target.value);
    console.log(e.target.value)

}   


  useEffect(()  => {
    getClients(fecha_consulta)
    getLocations();
    setName(SessionService.ClientConsult["nombre"])
    setMail(SessionService.ClientConsult["email"]);
    setDireccion(SessionService.ClientConsult["estado"]);
    console.log("ACTIVA "+SessionService.ClientConsult["activo"]);
    setActivo(SessionService.ClientConsult["activo"]);
    console.log("LOCALIZABLE "+ SessionService.ClientConsult["location_to_check"])
    console.log("DATA " + SessionService.ClientConsult);


    if (!("location_to_check" in SessionService.ClientConsult)) 
      { 
        setLocalizable(false); 
        setActualLocalization(locationDefault);
      }
    else {
      
      if (SessionService.ClientConsult["location_to_check"]=="True") 
      {
      

          setActualLocalization(locationDefault)


        setLocalizable(true);
      
      }
      else {setLocalizable(false)}
    }



    if (!("qr_to_check" in SessionService.ClientConsult)) 
      { 
        setQRble(true); 
      }
    else {
      console.log("QR TO CHECK "+SessionService.ClientConsult["qr_to_check"]);

      if (SessionService.ClientConsult["qr_to_check"]=="True") 
      {
      
        setQRble(true);
      
      }
      else {
        setQRble(false);
      }
    }

    
  },[]);


  return(
    <DashLayout sider={DashRouters} active="clients">
      <Row style={{justifyContent:'center'}}>
        <Col style={{maxWidth:'900px'}}>
        <h1>Datos Personales</h1>
        
          <Form>
          <Row>
          <Col md="6">
          <Form.Group className="md-3">
          <Form.Label>Nombre</Form.Label>
          <Form.Control type="text" value={Nombre} onChange={handleName}/>
          </Form.Group>
          </Col>
          <Col md="4">
          <Form.Group className="md-3">
          <Form.Label>Teléfono</Form.Label>
          <Form.Control  disabled={true} type="text" value={SessionService.ClientConsult["nick"]} onChange={null}/>
          </Form.Group>
          </Col>
          <Col md="1">
            <Row>
          <Form.Group className="md-3">
          <Form.Label></Form.Label>
          
          <Form.Check
            type={'checkbox'}
            label={"Activo "}
            id={"activo"}
            checked={active}
            disabled={!habilitado}
            className="mt-3"
            onChange={HandleActivate}
          />
          </Form.Group>
          <Form.Group className="md-3">
          <Form.Label></Form.Label>
          {activating && <Spinner animation="border" size="sm" style={{ marginTop: '2.9em' , marginLeft: '1em' }}/>}
          </Form.Group>
          </Row>
          </Col>
          </Row>

          <Row>
          <Col md="6">
          <Form.Group className="md-3">
          <Form.Label>Dirección</Form.Label>
          <Form.Control type="text" value={Direccion} onChange={handleDireccion}/>
          </Form.Group>
          </Col>
          <Col md="4">
          <Form.Group className="md-3">
          <Form.Label>E-Mail</Form.Label>
          <Form.Control type="text" value={Mail} onChange={handleMail}/>
          </Form.Group>
          </Col>
          <Col md="1">
          <Form.Group className="md-3">
          <Form.Label></Form.Label>

          </Form.Group>
          </Col>
          </Row>
          <Row>
          <Col md="6"> 



          <Row>
            <Col md="6">
          <Form.Group className="md-3 mt-2">
          <Form.Check
            type={'checkbox'}
            label={"QR obligatorio para registrar"}
            id={"activo"}
            checked={QRble}
            disabled={!habilitadoQ}
            className="mt-0"
            onChange={HandleQRble}
          />
          </Form.Group>
          </Col>
          <Col md="2">
          <Form.Group className="md-2 mt-2">
          <Form.Label></Form.Label>
          {QRblezating && <Spinner animation="border" size="sm" />}
          </Form.Group>
          </Col>
          </Row>






          <Row>
            <Col md="5">
          <Form.Group className="md-3 mt-2">
          <Form.Check
            type={'checkbox'}
            label={"Localizado para registrar"}
            id={"activo"}
            checked={localizable}
            disabled={!habilitadoL}
            className="mt-0"
            onChange={HandleLocalice}
            
    
          />
          </Form.Group>
          </Col>
          <Col md="1">
          <Form.Group className="md-2 mt-2">
          <Form.Label></Form.Label>
          {localizating && <Spinner animation="border" size="sm" />} {/*style={{ marginTop: '2.9em' , marginLeft: '1em' }}/>}*/}

          {localizable ?
          <button className="btn-transparent" style={{ float: 'right'}}><FontAwesomeIcon icon={faPlusCircle} onClick={HandleChangeLocation}/> </button>
            :
            <></>
          }
          </Form.Group>
          </Col>

          <Col md="6">
            {localizable ?
          <Form.Group>
                    <Form.Control onChange={HandleSelectNewLocation} as="select" custom value={ActualLocalization}>
                      {locationOptions}
                    </Form.Control>
          </Form.Group>
          :
          <></>
            }          
          
          </Col>

          </Row>
        
          </Col>
          <Col md="4"> 
          
          <Button active={activeUpdate && canUpdate} disabled={!activeUpdate} className="btn-submit-addons with-add" onClick={handleActualize}>
              {sending ? "Actualizando" : "Actualizar"}
                {sending && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
          </Button>

          </Col>
          <Col md="1">
          </Col>
          </Row>
          </Form>


          {localizable ?
          <>
          <Row>
            <Col>
            <h3 className="mb-3">Ubicaciones permitidas</h3>
            </Col>
 
          </Row>
          <Row className="mb-3 border-bottom">
            <Col>Nombre</Col>
            <Col>Dirección</Col>
            <Col md="2">Acion</Col>
          </Row>

          {stillLoaded ?
            <Row>
              <Col md="2" className="mx-auto d-flex align-items-center" style={{ marginTop: '5em' }}>
                <Spinner animation="border"/>
              </Col>
            </Row>
            :
            <>

          {AbledLocations.map(client => { 
            return (
              <Row className="mb-3">
                <Col className="">
                  <span>{client.name}</span>
                </Col>
                <Col className="">
                <span>{client.direccion}</span>
                </Col>
                <Col md="2" className="">
                <Button onClick={(event) => handleEraseEmployeeLocation(event, client)} type="submit" className="btn-transparent">
                  <FontAwesomeIcon icon={faTrash}/> 
                </Button>
                </Col>
              </Row>
            )}
            )}
            </>
            }
            </>
          :
          <></>
          }


          
          <div className="separator"></div>
          <hr/>
          <Row>
          <Col>
            <h1 className="mb-3">Asistencia</h1>
            </Col>
          </Row>

          <Row>
            <Col >
            <h3 className="mb-1">Hoy</h3>
            </Col>
            <Col className="my-auto">
            <span className="mb-1">{hoursToday}</span>
            </Col>
            <Col md="3">
            <h3 className="mb-1">Semana</h3>
            </Col>
            <Col className="my-auto">
            <span className="mb-1">{hoursWeek}</span>
            </Col>
            <Col>
            <h3 className="mb-1">Mes</h3>
            </Col>
            <Col className="my-auto">
            <span className="mb-1">{hoursMonth}</span>
            </Col>
    
            </Row>
            <hr/>
          <Row>

            <Col>
            <h1 className="mb-3">Estados del día</h1>

                  

            </Col>
            <Col><Form.Control type="date" value={fecha_consulta} onChange={handleFrom}/></Col>
          </Row>
          <Row className="mb-3 border-bottom">
            <Col>Fecha</Col>
            <Col>Hora</Col>
            <Col md="2">Tipo</Col>
          </Row>

          {stillLoaded ?
            <Row>
              <Col md="2" className="mx-auto d-flex align-items-center" style={{ marginTop: '5em' }}>
                <Spinner animation="border"/>
              </Col>
            </Row>
            :
            <>
          {clients.map(client => { 
            return (
              <Row className="mb-3">
                <Col className="">
                  <span>{client.date}</span>
                </Col>
                <Col className="">
                <span>{client.time}</span>
                </Col>
                <Col md="2" className="">
                <span>{client.event_type}</span>
                </Col>
              </Row>
            )}
          )}
            </>
            }

        </Col>
      </Row>
      {addingTx && <AddTxSelModal shown={addingTx} onSuccess={whenClosing} onCloseAction={whenClosing} maxMonthLimit={100} actualMonthLimit={5}/>}
    </DashLayout>
  )
}

export default ClientProjects;