import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import DashRouters from '../../configs/routers/dashboard-routers-private.json';
import { Row, Col, Alert, Card, Button, Form, FormControl, Spinner, InputGroup  } from 'react-bootstrap';
import ObexRequestsHandler from '../../handlers/ObexRequestsHandler';
import ButtonSubmit from '../basicComponents/buttonSubmit';
import { useHistory,useParams } from 'react-router-dom';
import SessionService from '../../services/SessionsService';
import ObexProjectsRequestHandler from '../../handlers/ObexProjectsRequestsHandler';

type ClientsProps = {}


const ClientBilling:FunctionComponent<ClientsProps> = (props) => {

  const history = useHistory();
  const { id } = useParams();
  //const { } = props;

  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [username, setUsername] = useState(String());
  const [email, setEmail] = useState(String());
  const [isValidated, setIsValidated ] = useState(false);
  const [stripeCustomerId, setSripeCustomerId ] = useState(String());
  const [stripeSignupCard, setStripeSignupCard ] = useState(String());
  const [stripeDefaultCard, setStripeDefaultCard ] = useState(String());
  const [isAdmin, setIsAdmin ] = useState(false);
  const [logo_empresa, setLogo] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [direccion, setDireccion] = useState('');
  const [telefono, setTelefono] = useState('');
  const [dentro, setDentro] = useState(false);
  const [stillLoaded, SetStillLoaded] = useState(false);


  const getClientsDetail = async () => {
    setLoading(true);
    SetStillLoaded(true);
    try {
      
      const result =  await ObexProjectsRequestHandler.get(`/ws/company_data.py/summary?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29`);
      const { resultado } = result;
      handleLogo(resultado["logo"]);
      setEmpresa(resultado["name"]);
      setDireccion(resultado["company_address"] +", "+resultado["company_localidad"] +", "+ resultado["company_provincia"] + " ("+resultado["company_cp"]+")");
      setTelefono(resultado["phone_number"]);

      console.log(result);

    } catch (error) {
      setError(error.message || error)
      setLoading(false);
      SetStillLoaded(false);
    };
    SetStillLoaded(false);
  };


  const getStatus = async () => {
    setLoading(true);
    try {
      
      const result =  await ObexProjectsRequestHandler.get(`/ws/employee_time_control.py/status_today?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29`);
      const { resultado } = result;

      setDentro(resultado["opened_check_in"])

      console.log(result);

    } catch (error) {
      setError(error.message || error)
      setLoading(false);
    };
  };

  const handleLogo = (url) => {
    setLogo(url);
  }

  useEffect(()  => {
    getClientsDetail();
    getStatus();
  },[]);


  return(
    <DashLayout sider={DashRouters} active="clients">
      <Row>
        <Col md="9" className="mx-auto">
          <Row>
            <Col>
              <h1 className="mb-3">Bienvenido {SessionService.NombreCuenta}!</h1>
            </Col>
          </Row>


          {stillLoaded ?
            <Row className="h-100" >
              <Col md="2" className="mx-auto d-flex align-items-center" style={{ marginTop: '15em' }}>
                <Spinner animation="border"/>
              </Col>
            </Row>
            :
            <>
          <Row>
          <Col>
          <Card className="product-detail-card mb-3">
              <Card.Body className="p-3">
                <Row>
                  <Col className="mx-auto d-block text-center">
                    <Form className="obex-form">
                    <Form.Row className="mb-3 mx-auto d-block">

                    <img id='logo' 
                          src={logo_empresa}
                          alt=""
                          title="Logo de la empresa" 
                          width="200" 
                          height="100" />

                      </Form.Row>
                      </Form>

                      </Col>
                      </Row>
                  </Card.Body>
                  </Card>
                </Col>
            </Row>

            <Row>
          <Col>
          <Card className="product-detail-card mb-3">
              <Card.Body className="p-3">
                <Row>
                  <Col className="mx-auto d-block text-center">
                    <Form className="obex-form">
                    <Form.Row className="mb-3 mx-auto d-block">
                      {empresa}
                      </Form.Row>
                      <Form.Row className="mb-3 mx-auto d-block">
                      {direccion}
                      </Form.Row>
                      <Form.Row className="mb-3 mx-auto d-block">
                      {telefono}
                      </Form.Row>
                      </Form>

                      </Col>
                      </Row>
                  </Card.Body>
                  </Card>
                </Col>
            </Row>


            <Row>
          <Col>
          <Card className="product-detail-card mb-3">
              <Card.Body className="p-3">
                <Row>
                  <Col className="mx-auto d-block text-center">
                    <Form className="obex-form">
                    <Form.Row className="mb-3 mx-auto d-block">
                      Actualmente estas:
                      </Form.Row>

                      <Form.Row className="mb-3 mx-auto d-block">
                      <h1>{dentro ? "Trabajando" : "De Salida"}</h1>
                      </Form.Row>


                      </Form>

                      </Col>
                      </Row>
                  </Card.Body>
                  </Card>
                </Col>
            </Row>

            </>
          } 

        </Col>
      </Row>





    </DashLayout>
  )
}

export default ClientBilling;